import { Link, ToccoFilter, ToccoTaxonomyInfo } from '~/types'

export interface ToccoItem {
  id: string
  title: string
  type: string
  link: Link
  date: string
  teaser: string
}

export const useTocco = async ({
  keywords,
  searchReset,
  taxonomies,
  filter,
  filterReset,
}: {
  keywords: Ref<string>
  searchReset: () => void
  taxonomies: ToccoTaxonomyInfo[]
  filter: ToccoFilter
  filterReset: () => void
}) => {
  const config = useRuntimeConfig()
  const route = useRoute()
  const { languageCode } = useLocale()

  const perPage = ref(30)
  const page = ref(route.query.page ? +route.query.page : 1)
  const result = reactive({
    loading: false,
    success: false,
    error: false,
    total: 0,
    items: [] as ToccoItem[],
  })
  const pages = computed(() => Math.ceil(result.total / perPage.value))

  const setQueryParams = async () => {
    const params = {
      ...(keywords.value ? { search: keywords.value } : {}),
      ...(filter.type.length ? { type: filter.type.join(',') } : {}),
      ...taxonomies.reduce(
        (acc, taxonomy) =>
          filter[taxonomy.name]?.length ? { ...acc, [taxonomy.param]: filter[taxonomy.name]?.join(',') } : acc,
        {},
      ),
      ...(filter.environment.length ? { environment: filter.environment.join(',') } : {}),
      ...(filter.sort.field ? { sort: filter.sort.field } : {}),
      ...(page.value ? { page: page.value.toString() } : {}),
      scroll: 'false',
    }

    await navigateTo({ query: params, replace: true })
  }

  const controller = ref<AbortController | null>(null)
  const search = async () => {
    if (controller.value) {
      controller.value.abort()
    }

    result.loading = true
    controller.value = new AbortController()

    try {
      const payload = {
        langcode: languageCode.value,
        text: keywords.value,
        from: perPage.value * (page.value - 1),
        size: perPage.value,
        sort: filter.sort,
        ...(filter.type.length ? { type: filter.type } : {}),
        ...taxonomies.reduce(
          (acc, taxonomy) =>
            filter[taxonomy.name]?.length ? { ...acc, [taxonomy.param]: filter[taxonomy.name] } : acc,
          {},
        ),
        ...(filter.environment.length ? { environment: filter.environment } : {}),
      }

      const response = await fetch(`${config.public.frontendBaseUrl}/api/elasticsearch/search-tocco`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        signal: controller.value.signal,
      }).then((response) => response.json())

      result.success = true
      result.items = response.hits as ToccoItem[]
      result.total = response.total
    } catch (error: any) {
      result.error = error.message ? error.message : error
      result.success = false
    }
    result.loading = false
  }

  watch([page, keywords, filter], async ([nPage, nKeywords], [pPage]) => {
    if ([1, 2].includes(nKeywords.length)) return
    if (nKeywords.length > 2 && filter.sort.field === 'title_keyword') {
      return (filter.sort.field = 'start_date')
    }
    if (nPage !== 1 && nPage === pPage) return (page.value = 1)

    await setQueryParams()
    await search()
  })

  const reset = () => {
    page.value = 1
    searchReset()
    filterReset()
  }

  return {
    search,
    result,
    page,
    pages,
    keywords,
    reset,
  } as const
}
