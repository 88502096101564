<script lang="ts">
import { Terms, useTerms } from '~/api/drupal'
import { ToccoTaxonomyInfo, ToccoFilter, ToccoFilterOptions } from '~/types'
import { capitalize } from '~/lib'
import { useScrollToTargetOnChange } from '~/composables/useScrollToTargetOnChange'

export const useToccoTerms = async () => {
  const route = useRoute()
  const { t } = useI18n()

  const taxonomies: ToccoTaxonomyInfo[] = [
    {
      name: 'event_category',
      vocabulary: 'tocco_event_category',
      param: 'category',
      label: t('tocco.list.filters.eventCategory'),
    },
    {
      name: 'supply_area',
      vocabulary: 'tocco_supply_area',
      param: 'supply_area',
      label: t('tocco.list.filters.supplyArea'),
    },
    {
      name: 'target_group',
      vocabulary: 'tocco_target_group',
      param: 'target_group',
      label: t('tocco.list.filters.targetGroup'),
    },
    {
      name: 'event_qualification',
      vocabulary: 'tocco_event_qualification',
      param: 'qualification',
      label: t('tocco.list.filters.eventQualification'),
    },
  ]
  const { terms, active } = await useTerms(taxonomies.map(({ vocabulary }) => vocabulary))

  taxonomies.forEach((taxonomy) => {
    active[taxonomy.vocabulary] = route.query[taxonomy.param] ? (route.query[taxonomy.param] as string).split(',') : []
  })

  const taxonomyVisible = ref<string>(taxonomies[0].name)

  const onToggleTaxonomyVisibility = (taxonomy: string) => {
    taxonomyVisible.value = taxonomyVisible.value === taxonomy ? '' : taxonomy
  }

  return {
    taxonomies,
    terms,
    active,
    taxonomyVisible,
    onToggleTaxonomyVisibility,
  }
}

export const useToccoFilters = ({
  taxonomies,
  terms,
  active,
}: {
  taxonomies: ToccoTaxonomyInfo[]
  terms: Ref<Terms>
  active: Record<TermVocabulary, string[]>
}) => {
  const route = useRoute()
  const { t } = useI18n()

  const toccoTypes = ['seminar', 'module', 'course', 'webinar']
  const toccoEnvironment = ['physical', 'online']
  const sortOptions = ['title_keyword', 'start_date']

  const filterOptions = {
    ...taxonomies.reduce((acc, taxonomy) => ({ ...acc, [taxonomy.name]: terms.value[taxonomy.vocabulary] }), {}),
    type: toccoTypes.map((type) => ({
      value: type,
      label: t(`tocco.toccoTypes.Tocco${capitalize(type)}`),
    })),
    environment: [
      { value: toccoEnvironment[0], label: t('tocco.list.filters.environmentPhysical') },
      { value: toccoEnvironment[1], label: t('tocco.list.filters.environmentOnline') },
    ],
    sort: [
      { value: sortOptions[0], label: t('tocco.list.sortToggle.title') },
      { value: sortOptions[1], label: t('tocco.list.sortToggle.date') },
    ],
  } as ToccoFilterOptions

  const filter = reactive({
    ...taxonomies.reduce((acc, taxonomy) => ({ ...acc, [taxonomy.name]: active[taxonomy.vocabulary] }), {}),
    type: route.query.type ? (route.query.type as string).split(',') : toccoTypes,
    environment: route.query.environment ? (route.query.environment as string).split(',') : toccoEnvironment,
    sort: {
      field: (route.query.sort ?? sortOptions[0]) as string,
      order: 'asc',
    },
  } as ToccoFilter)

  const filterReset = () => {
    taxonomies.forEach(({ name }) => (filter[name] = []))
    filter.type = toccoTypes
    filter.environment = toccoEnvironment
    filter.sort.field = sortOptions[0]
  }

  const sortText = computed(() => {
    const current = filterOptions.sort.findIndex((option) => option.value === filter.sort.field)
    const next = current + 1 >= filterOptions.sort.length ? 0 : current + 1
    return filterOptions.sort[next].label
  })
  const sortChange = () => {
    const current = filterOptions.sort.findIndex((option) => option.value === filter.sort.field)
    const next = current + 1 >= filterOptions.sort.length ? 0 : current + 1
    filter.sort.field = filterOptions.sort[next].value
  }

  return {
    filter,
    filterOptions,
    filterReset,
    sortText,
    sortChange,
  }
}

export const useToccoSearch = () => {
  const route = useRoute()
  const keywords = ref(route.query.search?.toString() ?? '')

  const resultsList = ref(null)
  const scrollToResults = () => {
    if (process.client) {
      const results = (resultsList.value as any).element
      const position = results.getBoundingClientRect().top + document.documentElement.scrollTop
      window.scrollTo({ top: position - 20, behavior: 'smooth' })
    }
  }

  const onSearchInputEnter = (event: KeyboardEvent) => {
    const target = event.target as HTMLInputElement
    target.blur()
    scrollToResults()
  }

  return {
    keywords,
    searchReset: () => (keywords.value = ''),
    resultsList,
    scrollToResults,
    onSearchInputEnter,
  }
}
</script>

<script setup lang="ts">
import { TermVocabulary, useTocco } from '~/api/drupal'

useHead({
  bodyAttrs: {
    class: 'tocco-listing',
  },
})

const { taxonomies, terms, active, taxonomyVisible, onToggleTaxonomyVisibility } = await useToccoTerms()
const { filter, filterOptions, filterReset, sortText, sortChange } = useToccoFilters({ taxonomies, terms, active })
const { keywords, searchReset, resultsList, scrollToResults, onSearchInputEnter } = useToccoSearch()
const { search, result, page, pages, reset } = await useTocco({
  keywords,
  searchReset,
  taxonomies,
  filter,
  filterReset,
})
await search()

const { scrollTarget } = useScrollToTargetOnChange(page)
</script>

<template>
  <SectionFilters>
    <div v-animate="{ animation: 'vertical-reveal' }" class="split">
      <SearchField
        v-model="keywords"
        :placeholder="$t('tocco.list.filters.textSearch')"
        @search="scrollToResults"
        @keyup.enter="onSearchInputEnter"
      />
      <Checkbox v-model="filter.type" class="filter-checkbox" :options="filterOptions.type" :required="false" />
    </div>
    <div class="terms">
      <ul class="toggles cul">
        <li v-for="taxonomy in taxonomies" :key="taxonomy.name" v-animate="{ animation: 'vertical-reveal' }">
          <ToccoButtonTaxonomy
            :active="taxonomyVisible === taxonomy.name"
            :count="filter[taxonomy.name].length"
            @click="onToggleTaxonomyVisibility(taxonomy.name)"
          >
            {{ taxonomy.label }}
          </ToccoButtonTaxonomy>
        </li>
      </ul>
      <TransitionGroup name="slide-fade" mode="out-in" tag="ul" class="cul">
        <li
          v-for="taxonomy in taxonomies"
          v-show="taxonomyVisible === taxonomy.name"
          :key="taxonomy.name"
          class="taxonomy"
        >
          <Tags v-model:active="filter[taxonomy.name]" :tags="filterOptions[taxonomy.name]" multiple />
        </li>
      </TransitionGroup>
    </div>
    <div v-animate="{ animation: 'vertical-reveal' }" class="extra">
      <Checkbox
        v-model="filter.environment"
        class="filter-checkbox"
        :options="filterOptions.environment"
        :required="false"
      />
      <button v-if="!keywords" class="sort text-small" @click="sortChange">
        <Icon name="mini-arrow-down" />
        {{ sortText }}
      </button>
      <button class="reset text-small" @click="reset">
        {{ $t('tocco.list.resetFiltersLabel') }}
      </button>
    </div>
  </SectionFilters>
  <Section ref="resultsList">
    <div ref="scrollTarget" class="tl-11 tl-o-1">
      <p v-if="result.items?.length === 0" v-animate="{ animation: 'vertical-reveal' }">
        {{ $t('tocco.list.noResults') }}
      </p>

      <div
        v-if="result.items?.length"
        v-animate="{ animation: 'vertical-reveal' }"
        class="results-count label f-medium"
      >
        {{ result.total + ' ' + $t('tocco.list.results') }}
      </div>
      <LinkListLarge v-if="result.items?.length">
        <template #default="{ isHovered }">
          <ToccoEventTeaser
            v-for="item in result.items"
            :key="item.id"
            v-animate="{ animation: 'vertical-reveal' }"
            v-bind="item"
            :dim="isHovered"
          />
        </template>
      </LinkListLarge>
      <Pagination v-if="pages > 1" v-model:page="page" :max="pages" />
    </div>
  </Section>
</template>
<style scoped lang="scss">
.split {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--gap);

  @include breakpoint(tl) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  :deep(.filter-checkbox .formkit-options) {
    @include breakpoint(ts down) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--gap);
    }
  }
}

.toggles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;

  @include breakpoint(t down) {
    .taxonomy-toggle {
      width: 100%;
      :deep(.text) {
        width: 100%;
        display: flex;
        > :last-child {
          margin-left: auto;
        }
      }
    }
  }
  @include breakpoint(t) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.taxonomy {
  @include breakpoint(tl down) {
    position: relative;
    width: calc(50% + 50vw);
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.tags {
  @include breakpoint(tl down) {
    flex-wrap: nowrap;
    width: 1000px;
  }

  :deep(li) {
    flex-shrink: 0;
  }
}
:deep(.filter-checkbox .formkit-options) {
  flex-direction: row;
  gap: 25px;
}

.extra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  button {
    color: var(--c-primary);
  }

  @include breakpoint(ts) {
    flex-direction: row;
    align-items: center;

    .sort {
      margin-left: auto;
    }

    .reset:only-of-type {
      margin-left: auto;
    }
  }
}

.results-count {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--c-blue-40);
}
.pagination {
  margin-top: 48px;
}
</style>
